import api from '@/api/index'
// initial state
const initialState = {
    // 220609 meej items 정의 추가
    themeList: { items : [] },
    themeAll: null,
    themeSearch: null,
    // 220609 meej theme.name 정의 추가
    themeDetail: {
        theme: {
        name: ''
        }
    },
    themeChart: {},
    // 220617 meej theme 차트 리스트 추가
    themeChartList: [],
    // 220617 meej theme Index 종목 리스트
    themeIndexStock: [],
    idx: '', // 관련종목 정렬 기준 구분값
    // 220719 meej 테마 index 리스트 
    themeIndexList: {},   
    items1: [],
    items2: [],
}

const state = () => ({
    ...initialState,
})

/**
 * mutation-types.js로 뺄지 고려 중
 */
const mutations = {

    CHART_SET1(state, data){
        state.items1 = data
        console.log("차트 셋1  : ", state.items1)
    }, 

    CHART_SET2(state, data){
        state.items2 = data
    }, 

    THEMELIST_SET(state, data) {
        state.themeList = data
    },
    /* 220719 meej 테마 index 리스트 */
    THEMEINDEXLIST_SET(state, data) {
        state.themeIndexList = data
    },
    THEMEALL_SET(state, data) {
        state.themeAll = data
    },
    THEMESEARCH_SET(state, data) {
        state.themeSearch = data
    },
    THEMEDETAIL_SET(state, data) {
        state.themeDetail = data
    },
    THEME_IDX_SET(state, data){
        state.idx = data
    },
    /* 220502 meej 테마 상세 chart 추가 */
    THEMECHART_SET(state, data) {
        state.themeChart = data
    }, 
    /* 220617 meej theme 차트 리스트 추가 */
    THEMECHART_LIST_SET(state, data, idx) {
        state.themeChartList[idx] = data
    }, 
    /* 220617 meej theme Index 종목 리스트 */
    THEME_INDEX_STOCK_SET(state, data) {
        state.themeIndexStock = data
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    
    async GET_CHART1({ commit }, params) {
        const { data } = await api.webChartGet(params)
        console.log("차트 데이터터터어어ㅓㅓㅓ어어ㅓ어 : ", data)
        commit('CHART_SET1', data)
    },

    async GET_CHART2({ commit }, params) {
        const { data } = await api.webChartGet(params)
        commit('CHART_SET2', data)
    },

    async GET_THEMELIST({ commit }, params) {
        // const { data } = await api.getThemeList(params)
        const { data } = await api.getThemeList(params)
        commit('THEMELIST_SET', data)
    },
    async GET_THEMEALL({ commit }, params) {
        // const { data } = await api.getThemeList(params)
        const { data } = await api.getThemeAll(params)
        commit('THEMEALL_SET', data)
    },
    async GET_THEMESEARCH({ commit }, params) {
        // const { data } = await api.getThemeList(params)
        const paramsOb = {
            pageNo: 1,
            countByPage: 30,
            name: params,
        }
        const { data } = await api.getThemeList(paramsOb)
        commit('THEMELIST_SET', data)
    },
    // 22.12.15 SUYE 테마DB 종목검색-관련테마 검색
    async GET_STOCKTHEMESEARCH({ commit }, params) {
        const paramsOb = {
            pageNo: 1,
            countByPage: 30,
            stockCode: params,
        }
        const { data } = await api.getThemeList(paramsOb)
        data.stockItems.sort((a, b) => {

            // idx값이 없을 경우 최하단
            if(a.stock_idx === '') a.stock_idx = "99"
            if(b.stock_idx === '') b.stock_idx = "99"

            // 테마중요도순
            if(Number(a.stock_idx) > Number(b.stock_idx)) return 1
            if(Number(a.stock_idx) < Number(b.stock_idx)) return -1

        });
        commit('THEMELIST_SET', data)
    },
    // 22.06.03 suye 관련종목 등락률 보기 추가 
    async GET_THEMEDETAIL({ commit, rootState }, params) {
        // const params = {
        //     code: param,
        // }
        const { data } = await api.getThemeDetail(params)
        
        /* 220502 meej 인포스탁 테마 - 관련종목 오름차순 정렬 오류 수정 */
       if(params.idx === '0'){
        data.stockItems.sort(function(a, b) { // 오름차순
            // 221102 meej 테마종목 - 신규 추가 된 종목은 무조건 마지막으로 
            if(a.index === '' && b.index !== '') return 1;
            return a.index - b.index 
        });
       } else if (params.idx === '1'){
        data.stockItems.sort(function(a, b) { // 등락률순
            return a.changeRate - b.changeRate 
        });
       }
        commit('THEMEDETAIL_SET', data)
        commit('THEME_IDX_SET', data)
    },
    /* 220502 meej 테마 상세 chart 추가 */
    async GET_THEMECHART({ commit }, param) {
        const { data } = await api.getChart(param)
        let data1 = []
        if(data.index_list.length > 0){
            data.index_list.sort(function(a,b){
                return a.x-b.x
            })
            // 220915 meej 전 거래일까지만 나오게 설정
            const today = new Date();
            const year = today.getFullYear();
            const month = ('0' + (today.getMonth() + 1)).slice(-2);
            const day = ('0' + today.getDate()).slice(-2);
            const dateString = year + month + day;    

            data.index_list = data.index_list.filter(
                function(el) {return Number(el.x) < Number(dateString)}
            )
            data1 = [{ data: data.index_list }]
        }
        commit('THEMECHART_SET', data1)
    }, 
    /* 220617 meej 테마 상세 chart 리스트 추가 */
    async GET_LIST_THEMECHART({ commit }, param) {
        const dataList = []
        for (const row of param) {
            try {
                const { data } = await api.getChart(row.code)
                if (data !== undefined && data.index_list !== undefined) {
                    let data1 = []
                    if (data.index_list.length > 0) {
                        data.index_list.sort(function (a, b) {
                            return a.x - b.x
                        })
                        // 220915 meej 전 거래일까지만 나오게 설정
                        const today = new Date();
                        const year = today.getFullYear();
                        const month = ('0' + (today.getMonth() + 1)).slice(-2);
                        const day = ('0' + today.getDate()).slice(-2);
                        const dateString = year + month + day;    
                        
                        data.index_list = data.index_list.filter(
                            function(el) {return Number(el.x) < Number(dateString)}
                        )
                        
                        data1 = [{ data: data.index_list }]
                    }
                    commit('THEMECHART_LIST_SET', data1, row.index)
                    dataList[row.index] = data1
                }
            } catch (err) {
                continue
            }
        }
        return dataList
    },
    /* 220617 meej 테마 index 종목 리스트 추가 */
    async GET_THEME_INDEX_STOCK({ commit }, param) {
        const { data } = await api.getIndexStock(param)
        commit('THEME_INDEX_STOCK_SET', data)
    },
    /* 220719 meej 테마 index 리스트 */
    async GET_THEME_INDEX_LIST({ commit }, params) {
        const { data } = await api.getThemeIndexList(params)
        commit('THEMEINDEXLIST_SET', data)
        return data
    },
    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {
    getThemeDetail: (state, getters, rootState, rootGetters) => {
        // console.log(rootState)
        // console.log(rootGetters["initial/initList"])
        // console.log(state.themeDetail)
        // console.log(state.themeDetail.theme)
        return state.themeDetail
    },
    getThemeSearch(state) {
        return (name) =>
            state.themeList.items.filter((item) => item.name.includes(name))
    },
}

export default {
    // namespaced: true
    state,
    mutations,
    actions,
    getters,
}