import api from '~/api/' // server
import routerinfo from '~/constants/routerinfo'
import storage, { keys } from '~/services/storage'


const tempPath = [] // 22.04.19 suye PATH

export default ({ app, store }) => {
    /**
     * Navigation Guard Step
     * 1. LocalStorage 키가 있는지 확인. -> 있으면 id값 store에 올리고 next() 준다. 없으면 다음단계 진행
     * 2. Hello에서 Session값을 보내서 확인. -> 있으면 next()만 주고, 없으면 다음단계 진행 (이 부분은 페이지 넘어갈 때 마다 계속 체크)
     * 3. 다 없으면 로그인페이지로 이동
     */
    
    app.router.beforeEach(async (to, from, next) => {
        // LocalStorage 확인
        // store에 로그인 정보가 있는지 먼저 확인 후, 로그인 진행
        const memberStore = store.state.member.memberInfo
        const notMember = routerinfo['allow-nomember']
        let toPath = to.path
        
        // 22.08.04 suye test  
        if(toPath == null || toPath === undefined || toPath === '') {
            toPath = '/' 
            console.log('--> auth.js toPath')
        }

        // 22.04.19 suye PATH
        if(toPath !== '/' && !toPath.includes('/Login')){
            if(tempPath.length > 0){  // 이전 값이 존재할 때 
                if(tempPath[tempPath.length-1] !== toPath){ // 바로 이전값과 같은 path 아닌지 확인
                    tempPath[tempPath.length-1] = toPath
                    // tempPath.push(toPath)
                }
            } else { // 값 비었을 때
                tempPath.push(toPath)
            }
        } else if (toPath === '/Login' && tempPath.length === 0) { // '/Login'이 toPath값이면 빈 tempPath 메인으로 '/' 
            tempPath.push('/')
        } else if (toPath !== '/' && tempPath.includes('/SignUp')){ // 로그인 후 회원가입 페이지 현출 방지 
            // console.warn('????!?!?!?!?!?!', tempPath)
            tempPath.push('/')
        }
        console.log('tempPath=', tempPath)
        
        storage.set(keys.pathInfo, tempPath[tempPath.length-1])

        // const fromPath = from.path

        console.log('toPath=', toPath)

        const pathAuth = await getPathAuth(toPath)
        const isAllow = pathAuth.success

        console.log('pathAuth=', pathAuth)

        // // path가 모든유저가 갈 수 있는 곳이면 session 체크 및 회원확인 하지않음
        // // if (isAllow){
        // //   return next()
        // // }

        if (!memberStore) {
            // 스토어에 로그인 정보가 없을 때,
            // 로컬스토리지에 저장된 값이 있는지 먼저 확인
            const result = await getStorageLoginInfo(store, next, toPath)
            console.log('storageresult=', result)

            if (result) {
                return next()
            } else {
                if (isAllow) {
                    // 해당 경로가 모든유저의 접근이 가능한 곳이면 넘어감
                    return next()
                } else {
                    if(toPath) {
                        if (toPath === '/EventNews/Month') {
                            store.dispatch('modal/SHOW_ALERT', {
                                content: '회원가입 후 이용하실 수 있습니다.',
                                callback: () => {
                                    next('/SignUp')
                                },
                            })
                            return
                        }
                    }
                    // 아니라면 로그인 모달 띄움
                    store.dispatch('modal/SHOW_ALERT', {
                        content: '로그인이 필요합니다.',
                        callback: () => {
                            next('/Login')
                        },
                    })
                    // next('/Login')
                }
                return
            }
        } else {
            // 스토어에 로그인 정보가 있을 때, 세션체크
            const result = await getSession(toPath)

            if (result) {
                return next()
            }
        }

        // 로그인 진행 후, memberStore 다시 체크해서 url허용 범위에 따라 next()
        if (memberStore) {
            next()
        } else {
            // eslint-disable-next-line no-lonely-if
            if (isAllow) {
                // 접근가능한 url에 해당되면 비회원도 페이지 진입
                next()
            } else {
                // 접근가능한 페이지가 아니면, modal 띄우고, login page로 redirect
                store.dispatch('modal/SHOW_ALERT', {
                    content: '로그인이 필요합니다.',
                })
                return next('/')
            }
        }
    })
}

const getStorageLoginInfo = async (store, next, toPath) => {
    const loginInfo = storage.get(keys.loginInfo)
    console.log('loginInfo=', loginInfo)    

    if (loginInfo) {
        // 로컬스토리지에 저장된 값이 있을 때,
        // 저장된 로그인정보가 유효한 세션인지 확인
        const sessionResult = await getSession(toPath)

        // 유효한 세션이 아니라면 저장된 key 값 삭제
        if (!sessionResult) {
            storage.remove(keys.loginInfo)

            return false
        } else {
            // 유효한 세션이라면
            const params = {
                id: loginInfo.id,
            }
            // 스토어에 id값 입력
            await store.dispatch('member/GET_MEMBER_INFO', params)

            return true
        }
    } else {
        // 로컬스토리지에 저장된 값이 없다면,

        return false
    }
}

const getSession = async (toPath) => {
    try {
        const params = {
            path: toPath,
        }

        const { data } = await api.sessionCheck(params)

        if (data.session.status === 'SUCCESS') {
            return true
        } else {
            return false
        }
    } catch (e) {
        console.log('getSessionError=', e)
        return false
    }
}

const getPathAuth = async (toPath) => {
    const params = {
        path: toPath,
    }

    const data = await api.sessionCheck(params)

    // console.log('data=', data)
    return data
}
