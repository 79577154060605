// 기본 state 값
const defaultState = {}

// initial state
const state = () => ({
    list: [],
})

/**
 * mutation-types.js로 뺄지 고려 중
 */
const mutations = {
    ADD(state, title) {
        // state.list.push({
        state.list.unshift({
            title,
            completed: false,
        })
    },
    REMOVE(state, { todo }) {
        state.list.splice(state.list.indexOf(todo), 1)
    },
    TOGGLE(state, todo) {
        console.log('TOGGLE todo', todo)
        todo.completed = !todo.completed
    },
    SET(state, todos) {
        state.list = todos
    },
}

const actions = {
    /* nuxtServerInit ({ commit }, { req }) {
    if (req.session.user) {
      commit('user', req.session.user)
    }
  }, */
    /*
  async nuxtServerInit({ dispatch }) {
    await dispatch('todos/GET_TODOS')
  },
 */

    async GET_TODOS({ commit }) {
        const todos = await this.$axios.$get(
            'https://jsonplaceholder.typicode.com/todos'
        )
        commit('SET', todos)
    },
}

const getters = {
    doneTodos: (state) => {
        return state.list.filter((todo) => todo.completed)
    },
    doneCount: (state, getters) => {
        return getters.doneTodos.length
    },
    getTodoById: (state) => (id) => {
        return state.list.find((todo) => todo.id === id)
    },
}

export default {
    // namespaced: true
    state,
    mutations,
    actions,
    getters,
}
