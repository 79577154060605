//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'RegularMemberJoin',

    props: {
        callback: null,
    },

    computed: {
        ...mapState('modal', ['regularMemberJoinInfo']),
    },

    methods: {
        ...mapMutations('member', ['SET_SHOW_PAYMENT']),
        ...mapActions('modal', ['HIDE_MODAL']),

        onClickRegularJoin() {
            this.regularMemberJoinInfo.callback &&
                this.regularMemberJoinInfo.callback()

            this.HIDE_MODAL()
            this.SET_SHOW_PAYMENT(true)
            this.$router.push({ path: '/MyPage/MemberInfo' }) // 가입 완료 시 메인으로 이동
        },

        onClickClose() {
            this.close()
        },
        onClickConfirm() {
            this.close()
        },

        close() {
            // this.alertInfo.callback && this.alertInfo.callback()
            this.HIDE_MODAL()
        },
    },
}
