import api from '~/api'
import { isEmptyObject } from '~/utils'

const initialState = {
    items: {
        items: [],
        kosdaq: {},
        kospi: {},
    },
    stock: {
        items: {},
    },
    report: {},
    recentIssueList:[],
    recentIssueDetail:[]
}

const state = () => ({
    ...initialState,
})

const mutations = {
    PORTFOLIO_SET(state, data) {
        state.items = data
    },
    STOCK_SET(state, data) {
        data.items.sort((a,b) => parseFloat(b.profit).toFixed(2) - parseFloat(a.profit).toFixed(2))
        state.stock = data
    },
    REPORT_SET(state, data) {
        // 초기화
        state.report = {}
        state.report = { ...data }
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
    SET_RECENTISSUE(state, data) {
        state.recentIssueList = data.data
    },
    SET_RECENTISSUE_DETAIL(state, data) {
        state.recentIssueDetail = data.data
    },
}

const actions = {
    async GET_RECENTISSUE({ commit }, params) {
        const { data } = await api.getRecentIssue(params)
        
        // 22.06.22 suye 최근 이슈발생 종목 열제목별 정렬 추가 
        if(params.order && params.order !== ''){
            if(params.order === 'totalCnt'){ data.items.sort((a,b) => b.totalCnt - a.totalCnt) }
            else if(params.order === 'issueCnt'){ data.items.sort((a,b) => b.issueCnt - a.issueCnt) }
            else if(params.order === 'disclCnt'){ data.items.sort((a,b) => b.disclCnt - a.disclCnt) }
            else if(params.order === 'recomCnt'){ data.items.sort((a,b) => b.recomCnt - a.recomCnt) }
            else if(params.order === 'opinCnt'){ data.items.sort((a,b) => b.opinCnt - a.opinCnt) }
        }
        commit('SET_RECENTISSUE', { data })
        return data
    },
    async GET_RECENTISSUE_DETAIL({ commit }, params) {
        const { data } = await api.getRecentIssueDetail(params)
        if(data !== undefined ){
            for(let i = 0; i<data.items.length;i++){
                if(data.items[i].content !== null && data.items[i].content !== ''){
                    data.items[i].content = data.items[i].content.replaceAll("\n","<br>").replaceAll('<br><br>', '<br>')
                }
                // status 에 따라 newsType1 설정 및 content 변경
                switch (data.items[i].newsType1) {
                    case "STOCK_COMPANY_OPINION" :
                        data.items[i].newsType1 = '투자의견'
                        if(data.items[i].content === null || data.items[i].content === ''){
                            data.items[i].content 
                                = data.items[i].stockCompanyName+" | 투자의견 : "+data.items[i].OPINION0
                            // 220609 meej data.items[i].PRICE 없을 경우
                            if(data.items[i].PRICE !== '-'){
                                data.items[i].content += "/"+(data.items[i].PRICE* 1).toLocaleString('ko-KR')
                            }else{
                                data.items[i].content += "/"+data.items[i].PRICE
                            }
                        }else{
                            data.items[i].content 
                                = data.items[i].stockCompanyName+" | "+data.items[i].content
                        }
                        break;
                    case "STOCK_ISSUE" :
                        data.items[i].newsType1 = '종목이슈'
                        break;
                    case "STOCK_NEW_RECOMMEND" :
                        data.items[i].newsType1 = '신규추천'
                        data.items[i].content 
                            = data.items[i].stockCompanyName+" ("+data.items[i].RECOMMEND_TYPE+") <br>"+data.items[i].content 
                            if(data.items[i].EXCLUSION0 === "1"){
                                data.items[i].content += "<br><span style='color:grey'>"
                                if(data.items[i].EXCLUDED_DATE0 !== null && data.items[i].EXCLUDED_DATE0 !== ''){
                                    const YYYY = data.items[i].EXCLUDED_DATE0.substring(0, 4)
                                    const MM = data.items[i].EXCLUDED_DATE0.substring(4, 6)
                                    const DD = data.items[i].EXCLUDED_DATE0.substring(6, 8)
                                    data.items[i].EXCLUDED_DATE0 = YYYY + '-' + MM + '-' + DD
                                    data.items[i].content += data.items[i].EXCLUDED_DATE0
                                }
                                if(data.items[i].CAUSE !== null && data.items[i].CAUSE !== ''){
                                    data.items[i].content += " "+data.items[i].CAUSE
                                }
                                data.items[i].content += "</span>"
                            }
                        break;
                    case "STOCK_DISCLOSURE" :
                        data.items[i].newsType1 = '공시'
                        break;
                    case "STOCK_52_WEEKS_NEW_HIGH" :
                        data.items[i].newsType1 = '52주 신고가'
                        break;
                    case "STOCK_JUMP" :
                        data.items[i].newsType1 = '상한가 및 급등주'
                        if(data.items[i].DAYS > 0){
                            data.items[i].content = data.items[i].content+" - "+data.items[i].DAYS+"일 연속"
                        }
                        break;
                    case "STOCK_SLUMP" :
                        data.items[i].newsType1 = '하한가 및 급락주'
                        if(data.items[i].DAYS > 0){
                            data.items[i].content = data.items[i].content+" - "+data.items[i].DAYS+"일 연속"
                        }
                        break;
                    case "STOCK_FEATURED" :
                        data.items[i].newsType1 = '특징주'
                        break;
                    case "STOCK_STRONG_BUY" :
                        data.items[i].newsType1 = '강력매수'
                        data.items[i].content 
                            = data.items[i].stockCompanyName+"  <br>"+ data.items[i].content 
                        break;
                }

                // yyyy-mm-dd 으로 보이게 변경
                data.items[i].showDate = data.items[i].showDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
            }
        }
        commit('SET_RECENTISSUE_DETAIL', { data })
        return data
    },
    async GET_PORTFOLIO({ commit }) {
        const { data } = await api.getPortfolioEarningRateRanking()

        commit('PORTFOLIO_SET', data)
    },
    async GET_STOCK({ commit }) {
        const { data } = await api.getStockEarningRateRanking()
       
        commit('STOCK_SET', data)
    },
    async GET_NEW_REPORT({ commit }, params) {
        const { data } = await api.getStockFirmOpinionAndStrongBuying(params)
        const items = data.items
        /* 20210526 shb 추가 */
        if (!isEmptyObject(items)) {
            let obj = {}
            let group = []
            const result = []
            // groupby 전 list 정렬
            /* 
                220314 meej
                list 정렬 기준 : 종목으로 변경
            */
            items.sort(function (a, b) {
                return a.stockCode < b.stockCode
                    ? -1
                    : a.stockCode > b.stockCode
                    ? 1
                    : 0
            })
            // let leng = 0
            //  같은 회사면 같은 배열에 객체를 묶자! -> 220314 meej 같은 종목이면 같은 배열의 객체로 묶음
            //  acc - 누산기 = return cur 리턴 값을 가진다
            //  cur 다음 값
            // it - 회전 인덱스
            items.reduce((acc, cur, it, arr) => {
                //  첫번째 acc default =  undefind
                if (it !== 0) {
                    // 누산기에 있는 값과 현재 값이 같으면
                    if (acc.stockCode === cur.stockCode) {
                        // 2차원 배열중 2차원에 push
                        group.push(cur)
                    } else {
                        // 배열 길이 앞에 넣기
                        // leng = group.length
                        obj.len = group.length
                        obj.items = group
                        // group.unshift(leng)
                        // 누산기 있는 값과 현재 값이 달라졌다.
                        //  2차원 배열중 1차원에 push
                        result.push(obj)
                        // 2차원 비움
                        group = []
                        obj = {}
                        // 동일 값이 없는 하나밖에 없는 값 2차원 push
                        obj.lead = cur
                        // group.push(cur)
                    }
                } else {
                    //  첫번째 무조건 새배열에 push
                    obj.lead = cur
                    // group.push(cur)
                }
                return cur
            }, {})

            // leng = group.length
            obj.len = group.length
            obj.items = group
            // group.unshift(leng)
            // result.push(group)
            result.push(obj)
            result.sort((f,r) => {
                if (f.lead.showDate < r.lead.showDate) {
                    return 1;
                }
                if (f.lead.showDate > r.lead.showDate) {
                    return -1;
                }
    // a must be equal to b
                return 0;
            })
            commit('REPORT_SET', data)
            return result
        }
    },
    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}
const getters = {
    getSelectBoxCon: (state) => {
        let sbArr = []
        if (state.stockRecom.stock) {
            const stock = state.stockRecom.stock
            sbArr = [...new Set(stock.map((it) => it.StockCom))]
        }

        return sbArr
    },
    getStockfirmArr: (state) => {},
}

export default {
    // namespaced: true
    state,
    mutations,
    actions,
    getters,
}
