//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TabMenu',
    props: {
        tabData: Array,
    },
    methods: {
        focusTab(route) {
            this.$router.push(route)
        },
    },
}
