//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Notice',
  methods: {
      onClickMove() {
          this.$router.push({
              path: '/Theme/ThemeIndex',
          })
      },
    },
}
