import api from '~/api/' // server
// import api from '~/api/mock' // local

const state = () => ({
    lists: {
        TechnicalAnalysis: [],
    },
})

const mutations = {
    SET_(state, value) {
        state.value = value
    },

    SET__(state, data) {
        state.value = data
    },
}

const actions = {
    async GET_({ commit }, params) {
        const { data } = await api.get_(params)

        commit('SET__', data)
    },
}

const getters = {
    get_(state) {
        return (newsType, id) =>
            state.newsList[newsType].items.find((List) => List.id === id)
    },

    get__(state) {
        return (name) =>
            state.themeList.items.filter((item) => item.name.includes(name))
    },

    get___: (state, getters, rootState, rootGetters) => {
        // console.log(rootState)
        // console.log(rootGetters["initial/initList"])
        // console.log(state.themeDetail)
        // console.log(state.themeDetail.theme)
        return state.themeDetail
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
