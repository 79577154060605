import Vue from 'vue'

import * as dayjs from 'dayjs'
import { DAY_NAMES } from '~/constants'

Vue.filter('formatDate', (value) => {
    const date = new Date(value)
    return date.toLocaleString(['en-US'], {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })
})

Vue.filter('date', (value) => {
    const re = /^20[0-9]{6}/
    if (re.test(value)) {
        return dayjs(value).format('YYYY. MM. DD')
    }
    return value
})

Vue.filter('dashdate', (value) => {
    if (!value) return 'No Data'
    return dayjs(value).format('YYYY-MM-DD')
})

Vue.filter('HHmmss', (value) => {
    return dayjs(value).format('HH:mm:ss')
})

Vue.filter('dateDay', (value) => {
    return (
        dayjs(value).format('YYYY. MM. DD') +
        ' (' +
        DAY_NAMES[dayjs(value).day()] +
        ')'
    )
})

Vue.filter('yearMonthKor', (value) => {
    return dayjs(value).format('YYYY년 MM월')
})

Vue.filter('day', (value) => {
    return DAY_NAMES[dayjs(value).day()]
})

Vue.filter('time', (value) => {
    const HH = value.substring(0, 2)
    const mm = value.substring(2, 4)
    const ss = value.substring(4, 6)

    return `${HH}:${mm}:${ss}`
})

//

// paging filter
Vue.filter('isNull', (value) => {
    return value === undefined || value === null
})

Vue.filter('won', (value) => {
    const num = Number(value)
    return num.toLocaleString()
})

// market Type return
Vue.filter('market', (value) => {
    console.log('VAULES=', value)

    if (value.trim() === 'K') {
        return '코스닥'
    } else if (value.trim() === 'Y') {
        return '코스피'
    } else {
        return '시장타입 error'
    }
})

Vue.filter('processStatus', (value) => {
    switch (value) {
        case 0:
            return '처리 중'
        case 1:
            return '처리 완료'
        default:
            break
    }
})

Vue.filter('answerStatus', (value) => {
    switch (value) {
        case 0:
            return '확인 중'
        case 1:
            return '답변 완료'
        default:
            break
    }
})

Vue.filter('bankingStatus', (value) => {
    switch (value) {
        case 0:
            return '확인 중'
        case 1:
            return '처리 완료'
        default:
            break
    }
})

export const yearsToDash = (value) => {
    return dayjs(value).format('YYYY-MM-DD')
}

export const cleanPriceValue = (value) => {
    return value.replace('"', '').replace(',', '')
}
