
import Vue from 'vue'
import vueCookies from 'vue-cookies'
import api from '~/api/' // server
Vue.use(vueCookies);
Vue.$cookies.config("1d");

const initialState = {
    // 기업개요 fetch 결과
    market_index: {
        items: [
            {
                price: 0,
                change: 0,
                rate: 0,
                name: '',
            },
            {
                price: 0,
                change: 0,
                rate: 0,
                name: '',
            },
        ],
    },
    todays_world: {
        items: [{ price: '', change: '', name: '', groupName: '' }],
    },
    top_theme: {
        items: [],
        recentDate: '',
    },
    word_top_theme: {
        items: [],
        recentDate: '',
    },
    cal_detail: {
        id: '',
        eventDate: '',
    },
}

const state = () => ({
    ...initialState,
})

const mutations = {
    SET_MARKET_INDEX(state, { data }) {
        state.market_index = {
            ...data,
        }
    },
    SET_TODAYSWORLD(state, { data }) {
        state.todays_world = {
            ...data,
        }
    },
    SET_TOPTHEME(state, { data }) {
        console.log("state : ", state)
        console.log("data : ", data)
        state.top_theme = {
            ...data,
        }
    },
    WORD_SET_TOPTHEME(state, { data }) {
        console.log("state : ", state)
        console.log("data : ", data)
        state.word_top_theme = {
            ...data,
        }
    },
    SET_CALENDAR_INFO(state, { params }) {
        state.cal_detail = { ...params }
    },

    SET_REMOVE_CALENDAR_INFO(state) {
        state.cal_detail = { id: '', eventDate: '' }
    },

    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    // common/index api call
    async GET_MARKET_INDEX({ commit }) {
        // console.warn('GET_MARKET_INDEX beforeFetch')

        const params = {}

        const { data } = await api.getIndex(params)

        // console.warn('GET_MARKET_INDEX data', data)
        console.log('data', data)
        if (data.items.length === 0) {
            const errorMessage = {
                data: {
                    items: [
                        { price: 0, rate: 0 },
                        { price: 0, rate: 0 },
                    ],
                },
            }
            commit('SET_MARKET_INDEX', errorMessage)
            return
        }

        commit('SET_MARKET_INDEX', { data })
    },
    // common/todayworld api call
    async GET_TODAYSWORLD({ commit }) {
        // console.warn('GET_TODAYSWORLD beforeFetch')

        const params = {}

        // const { data } = await api.getTodaysWorld(params)
        await api.getTodaysWorld(params).then((res)=>{
            if (res && res.success) {
                const { data } = res
                commit('SET_TODAYSWORLD', { data })
            }else{
                console.warn("main_page todayworld -> ",res.success)
                alert('main_page todayworld err')
                commit('SET_TODAYSWORLD', null)
            }
        })
        .catch((err)=>{
            console.warn("main_page todayworld -> ", err)
            alert(err)
            commit('SET_TODAYSWORLD', null)
        })


        // console.warn('GET_TODAYSWORLD data', data)
        // commit('SET_TODAYSWORLD', { data })
    },

    // common/toptheme api call
    async GET_TOPTHEME({ commit }, params) {
        // console.warn('GET_TOPTHEME beforeFetch')
        // const { data } = await api.getTopTheme(params)
        await api.getTopTheme(params).then((res)=>{
            if (res && res.success) {
                const { data } = res
                commit('SET_TOPTHEME', { data })
            }else{
                console.warn("main_page toptheme -> ",res.success)
                alert('main_page toptheme err')
                commit('SET_TOPTHEME', null)
            }
        })
        .catch((err)=>{
            console.warn("main_page toptheme -> ", err)
            alert(err)
            commit('SET_TOPTHEME', null)
        })

        // commit('SET_TOPTHEME', { data })
        // console.warn('GET_TOPTHEME params', params, ' data', data)
    },


    // common/toptheme api call
    async WORD_GET_TOPTHEME({ commit }, params) {
        // console.warn('GET_TOPTHEME beforeFetch')
        // const { data } = await api.getTopTheme(params)
        await api.getTopTheme(params).then((res)=>{
            if (res && res.success) {
                const { data } = res
                commit('WORD_SET_TOPTHEME', { data })
            }else{
                console.warn("main_page toptheme -> ",res.success)
                alert('main_page toptheme err')
                commit('WORD_SET_TOPTHEME', null)
            }
        })
        .catch((err)=>{
            console.warn("main_page toptheme -> ", err)
            alert(err)
            commit('WORD_SET_TOPTHEME', null)
        })

        // commit('SET_TOPTHEME', { data })
        // console.warn('GET_TOPTHEME params', params, ' data', data)
    },


    async GET_RELOAD_MAIN({ dispatch }, params) {
        await dispatch('GET_MARKET_INDEX')
        
        // eslint-disable-next-line camelcase
        const toptheme_params = {
            type: params,
        }

        // await dispatch('GET_TOPTHEME', toptheme_params)
        await dispatch('GET_TODAYSWORLD')
    },

    GET_CALENDAR_INFO({ commit }, params) {
        commit('SET_CALENDAR_INFO', { params })
    },

    REMOVE_CALENDAR_INFO({ commit }) {
        commit('SET_REMOVE_CALENDAR_INFO')
    },

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {
    kospi(state) {
        if (state.market_index.items[0] === undefined) {
            state.market_index.items[0] = {
                price: 0,
                change: 0,
                rate: 0,
                name: '',
            }
        }
        return state.market_index.items[0]
    },
    kosdaq(state) {
        if (state.market_index.items[1] === undefined) {
            state.market_index.items[1] = {
                price: 0,
                change: 0,
                rate: 0,
                name: '',
            }
        }
        return state.market_index.items[1]
    },
    todaysworld(state) {
        let listToJson = {}

        for (const item of state.todays_world.items) {
            const name = item.name

            listToJson = { ...listToJson, [name]: item }
        }

        return listToJson
    },
    toptheme(state) {
        console.log(state)
        return state.top_theme.items
    },
    word_toptheme(state) {
        console.log(state)
        return state.word_top_theme.items
    },

    topthemeRecentDate(state){
        return state.top_theme.recentDate
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}