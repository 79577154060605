//
//
//
//
//
//

import Footer from '~/components/Common/Layout/Footer'

export default {
    components: { Footer },
    data() {
        return {
            windowRef: null,
        }
    },
    methods: {},
}
