//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { APP_VERSION } from '~/constants'

export default {
    data() {
        return {
            APP_VERSION,
        }
    },
}
