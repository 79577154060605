/**
 * polyfills
 */

// ;(() => {

// Object.values
if (typeof Object.values === 'undefined') {
    Object.values = function (obj) {
        const array = []
        for (const key in obj) {
            array.push(obj[key])
        }
        return array
    }
}

// Object.keys
if (typeof Object.keys === 'undefined') {
    Object.keys = function (obj) {
        const array = []
        for (const key in obj) {
            array.push(obj[key])
        }
        return array
    }
}

// Object.assign polyfill
if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
        value: function assign(target) {
            // .length of function is 2
            'use strict'
            if (target == null) {
                // TypeError if undefined or null
                throw new TypeError(
                    'Cannot convert undefined or null to object'
                )
            }

            const to = Object(target)

            for (let index = 1; index < arguments.length; index++) {
                const nextSource = arguments[index]

                if (nextSource != null) {
                    // Skip over if undefined or null
                    for (const nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (
                            Object.prototype.hasOwnProperty.call(
                                nextSource,
                                nextKey
                            )
                        ) {
                            to[nextKey] = nextSource[nextKey]
                        }
                    }
                }
            }
            return to
        },
        writable: true,
        configurable: true,
    })
}

// domElement.closest
/* if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this

    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
} */

// Array.prototype.includes()
// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value(searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            // 1. Let O be ? ToObject(this value).
            const o = Object(this)

            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = o.length >>> 0

            // 3. If len is 0, return false.
            if (len === 0) {
                return false
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            const n = fromIndex | 0

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            function sameValueZero(x, y) {
                return (
                    x === y ||
                    (typeof x === 'number' &&
                        typeof y === 'number' &&
                        isNaN(x) &&
                        isNaN(y))
                )
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true
                }
                // c. Increase k by 1.
                k++
            }

            // 8. Return false
            return false
        },
    })
}

// String.prototype.includes()
if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict'
        if (typeof start !== 'number') {
            start = 0
        }

        if (start + search.length > this.length) {
            return false
        } else {
            // eslint-disable-next-line
            return this.indexOf(search, start) !== -1
        }
    }
}

/**
 * Object.entries
 */
if (!Object.entries) {
    Object.entries = function (obj) {
        const ownProps = Object.keys(obj)
        let i = ownProps.length
        const resArray = new Array(i) // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

        return resArray
    }
}

/**
 * closest
 */

//  Element is not defined 나옴
/* if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this

    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
} */

// })()
