//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'Alert',

    data() {
        return {
            depositName: '',
            cashReceiptNumber: '',
            cashReceiptTypeIdx: 0,

            accountInfoShow: false,
        }
    },

    computed: {
        ...mapState('modal', ['depositInfo', 'depositPayInfo']),
    },

    watch: {
        cashReceiptTypeIdx(val, oldVal) {
            // 미발행이면 입력값 삭제
            if (parseInt(val) === 0) {
                this.cashReceiptNumber = ''
            } else {
                this.$nextTick(() => {
                    this.$refs.cashReceiptNumber.focus()
                })
            }
        },
    },
    beforeDestroy() {
        this.CLEAR_DATA()
    },

    methods: {
        ...mapActions('modal', ['HIDE_MODAL']),
        ...mapMutations('member', ['SET_DEPOSIT_PAGE']),

        onClickClose() {
            this.close()
        },

        onClickCancel() {
            this.close()
        },

        onClickPrint() {
            // this.close()
        },

        onClickConfirm() {
            this.close()
            this.accountInfoShow = false

            this.CLEAR_DATA()
        },

        onClickNext() {
            if (this.depositName === '') {
                alert('입금자 이름을 입력하세요.')
                this.$refs.depositName.focus()
                return
            }
            if (
                parseInt(this.cashReceiptTypeIdx) !== 0 &&
                this.cashReceiptNumber === ''
            ) {
                alert('현금영수증 발급 정보를 입력하세요.')
                this.$refs.cashReceiptNumber.focus()
                return
            }

            this.depositInfo.callback &&
                this.depositInfo.callback({
                    depositName: this.depositName,
                    cashReceiptNumber: this.cashReceiptNumber,
                    cashReceiptTypeIdx: this.cashReceiptTypeIdx,
                })
            this.close()
            // this.accountInfoShow = true // 입금계좌 정보 보이게
        },

        close() {
            this.HIDE_MODAL()
        },

        CLEAR_DATA() {
            this.depositName = ''
            this.cashReceiptNumber = ''
            this.cashReceiptTypeIdx = 0
        },
    },
}
