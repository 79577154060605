//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import MarqueeText from 'vue-marquee-text-component'

import anime from 'animejs/lib/anime.js'
import api from '~/api/' // server

const TARGET_MARGIN_TOP = -18
// let isAniming = false // 연속된 anime 막기 위해 사용
// let intervalId = null
const MOVING_VALUE = -10000
const MOVING_INTERVAL = 200000
let movingCnt = 0

export default {
    name: 'TickerHorizental',

    components: {
        // MarqueeText,
    },

    data() {
        return {
            // index: 0,
            news: [],

            isMoving: false,
            intervalCounter: 0,
            interval: undefined,
        }
    },

    watch: {
        intervalCounter() {
            this.getData()
        },
    },

    created() {
        this.getData()
        this.getDataInterval()
    },

    mounted() {
        // setTimeout(this.updateTicker, 1000)
        setTimeout(this.startMarquee, 1000)
    },

    destroyed() {
        clearInterval(this.interval)
    },

    methods: {
        startMarquee() {
            this.isMoving = true
        },

        updateTicker() {
            movingCnt++
            this.moveMarguee()
        },

        async getData() {
            const nextKey = ''
            const res = (await api.getTickerList(nextKey)).data
            // 20210901 shb 데이터 안들왔을때 에러 |  예외 처리 추가.
            try {
                this.news = res.items.slice(0, 10)
            } catch (error) {
                this.news = []
            }
        },

        onClickTickerItem(item) {
            const id = item.id
            const newsType = item.newsType1
            // 유료회원 구분을 위해 sendDate 추가
            const sendDate = item.sendDate

            this.$router.push({
                path: '/MarketNewsFlash',
                query: { id, newsType, sendDate },
            })
        },

        getDataInterval() {
            this.interval = setInterval(() => {
                this.intervalCounter += 1
            }, 3000000)
        },

        moveMarguee() {
            /* const wrapper = this.$refs.wrapper
      console.log('this.$refs', this.$refs)
      console.log('wrapper', wrapper)
      if (!wrapper) return

      const ul = wrapper.getElementsByTagName('ul')[0]

      const ul = this.$refs.ul
      console.log('this.$refs', this.$refs)
      console.log('ul', ul)
      if (!ul) return
      console.log('ul.style.left', ul.style.left)

      const li = ul.getElementsByTagName('li')[0]
      console.log('li.style.width', li.style.width)

      let updates = 0

      anime({
        // targets: wrapper,
        targets: ul,
        // targets: li,
        // left: ul.style.left.split('px')[0] - 100,
        translateX: MOVING_VALUE * movingCnt,
        // left: 100,
        easing: 'linear',
        duration: MOVING_INTERVAL,
        loop: true,

        update(anim) {
          updates++
          // 첫 item을 마지막으로 옮기기 위한 처리
          if (updates % 500 === 0) {
            console.log('updates', updates)

            const li = ul.getElementsByTagName('li')[0]

            ul.appendChild(li)
          }
          // progressLogEl.value = 'progress : ' + Math.round(anim.progress) + '%'
          // updateLogEl.value = 'updates : ' + updates
        },

        // targets: li,
        // // marginTop: TARGET_MARGIN_TOP,
        // // // backgroundColor: '#FFF',
        // // // borderRadius: ['0%', '50%'],
        // translateX: 100,
        // easing: 'easeInOutQuad',
        // duration: 500,
      }) */
        },
    },
}
