/* eslint-disable */
import Vue from 'vue'

import { Fragment } from 'vue-frag'
Vue.component('Fragment', Fragment)

// TODO : vee-validate.js 로 이동 필요
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from 'vee-validate'
// import en from 'vee-validate/dist/locale/en.json'
import ko from 'vee-validate/dist/locale/ko.json'
import * as rules from 'vee-validate/dist/rules'

// install rules and localization
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule])
})

// 22.05.11 suye 아이디 이메일형식 허용
// 특수문자 검출 필터
extend('specialchars', {
    validate: (value) => {
        const regex = /[\{\}\[\]\/?.;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g                            // 일반 특수문자 정규식
        const regexKor = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g                                                       // 한글 자모음 정규식
        const regexEmail = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/                     // 이메일 허용 정규식 
        const test = regex.exec(value) // null이면 통과 값 있으면 검출 
        // console.log('id validate? ', test) 
        const test2 = regexKor.exec(value) // null 이면 통과 값 있으면 검출 
        // console.log('id kor validate? ', test2) 
        // 특문 검출시 이메일 형식인지 체크 
        
        if(test2 == null){
            if(test !== null){ 
                const testEmail = regexEmail.exec(value) // null 아니면 통과
                // console.log('id(Email-type) validate? ', testEmail) 
                if(testEmail !== null){ return testEmail } else { !testEmail }
            } else {
                return true
            }   
        } else {
            return false
        }
        
    },
})

// 220608 meej 닉네임 rules 변경 : (빈칸 + 1자) 일때 변경 불가능하게 수정
extend('nickNameMin', {
    validate: (value) => {
        if(value.replace(/(\s*)/g, '').length < 2){
            return '변경할 닉네임 항목의 값은 최소 2글자이어야 합니다'
        }else {
            return true
        }
    }
})

/*
 ** LOCALE 설정
 */

localize('ko', ko)
localize({
    ko: {
        messages: {
            // generic rule messages...
        },
        fields: {
            id: {
                required: '아이디를 입력해주세요.',
                specialchars:
                    '아이디에 한글, / : * ? " < > | # 가 포함되어 있습니다.',
                alpha_num: '아이디는 영어 또는 숫자로 이루어져야 합니다.',
                max: '아이디는 30자 이하로 입력해야 합니다.',
                min: '아이디는 4자 이상 입력해야 합니다.',
            },
            password: {
                required: '비밀번호를 입력해주세요.',
                max: '비밀번호는 16자 이하 입력해야 합니다.',
                min: '비밀번호는 8자 이상 입력해야 합니다.',
            },
            '현재 비밀번호': {
                required: '현재 비밀번호를 입력해주세요.',
                max: '비밀번호는 16자 이하 입력해야 합니다.',
                min: '비밀번호는 8자 이상 입력해야 합니다.',
            },
            passwordConfirm: {
                required: '비밀번호 확인을 입력해주세요.',
                max: '비밀번호는 16자 이하 입력해야 합니다.',
                min: '비밀번호는 8자 이상 입력해야 합니다.',
            },
            '비밀번호 확인': {
                required: '비밀번호 확인을 입력해주세요.',
                max: '비밀번호는 16자 이하 입력해야 합니다.',
                min: '비밀번호는 8자 이상 입력해야 합니다.',
            },
            nickname: {
                required: '닉네임을 입력해주세요.',
                max: '닉네임은 20자 이하 입력해야 합니다.',
                min: '닉네임은 2자 이상 입력해야 합니다.',
            },
            phone: {
                required: '핸드폰 번호를 입력해주세요.',
                integer: '- 를 제외한 숫자만 입력하여야 합니다.',
                max: '핸드번 번호가 너무 깁니다.',
                min: '핸드폰 번호가 너무 짧습니다.',
            },
            인증번호: {
                required: '인증번호를 입력해주세요.',
                integer: '숫자만 입력하여야 합니다.',
            },
            snNumber: {
                integer: '숫자만 입력하여야 합니다.',
            },
        },
    },
})

// Register it globally
// main.js or any entry file.
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
