export const PAYMENT_TYPES = [
    { id: 1, code: 'CARD', name: '카드결제' },
    { id: 2, code: 'MOBILE', name: '휴대폰결제' },
    // { id: 3, code: 'ACCOUNT', name: '실시간 계좌이체' },
    { id: 3, code: 'DEPOSIT', name: '무통장입금' },
    // { id: 4, code: 'AUTO_PAY', name: '정기결제' },
]

export const PAYMENT_PERIODS = [
    { id: 1, code: 'w1', name: '1주일', price: 9900, desc: '' },
    { id: 2, code: 'm1', name: '1개월', price: 33000, desc: '' },
    { id: 3, code: 'm3', name: '3개월', price: 89100, desc: '(10% 할인)' },
    { id: 4, code: 'm6', name: '6개월', price: 168300, desc: '(15% 할인)' },
    { id: 5, code: 'y1', name: '12개월', price: 316800, desc: '(20% 할인)' },
]

export const CASH_RECEIPT_TYPES = [
    { id: 1, code: 'N', name: '미발행' },
    { id: 2, code: 'I', name: '소득공제용' },
    { id: 3, code: 'E', name: '지출증빙용' },
]

export const KAKAO_TYPES = [
    { id: 1, code: 'Y', name: '카카오톡' },
    { id: 2, code: 'S', name: '문자' },
    { id: 3, code: 'N', name: '안받음' },
]
