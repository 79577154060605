import api from '~/api/' // server
// import api from '~/api/mock' // local

const initialState = {
    initial: {},
}

const state = () => ({
    ...initialState,
})

const mutations = {
    SET_INIT_DATA(state, { data }) {
        state.initial = { ...state.initial, data }
    },

    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    async GET_INIT_DATA({ commit }, params) {
        // console.warn('LOAD INITIAL DATA.....')
        // TODO: sDate, eDate 유무에 따라 다르게 처리해야함.
        const { data } = await api.getInitial(params)

        // console.warn('INITIAL:::', data)
        commit('SET_INIT_DATA', { data })
    },

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {
    initList: (state) => {
        return state.initial.data && state.initial.data.stockItems
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
