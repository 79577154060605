/**
 * Object
 */
export const getKeyByValue = (object, value) => {
    // console.log('getKeyByValue object, value', object, value)
    return Object.keys(object).find((key) => {
        const val = object[key]
        if (Array.isArray(val)) {
            return val.includes(value)
        } else {
            return val === value
        }
    })
}
/*
 * borad,Blog 공통 함수
 *
 */
export const changePage = (value, data, board, getList) => {
    const curBlock = Math.ceil(value.Page / data.blockSize)
    // curPage 변경 부분
    data.curPage = value.Page
    switch (value.flag) {
        case 'Next':
            console.log('board.nextKey')
            // 뒤로가기를 눌렀을때 돌아가기위해 블록 첫번째 값을 저장하고 있는다.
            data.prevData.push(board.items[0].id)
            console.log(data.prevData)
            getList(board.nextKey)

            data.targetIndex = 0
            break
        case 'Prev':
            // 돌아갈때는 next 에서 저장한 데이터의 id 로 검색 한다.
            console.log('data.prevData.pop()')
            console.log(data.prevData.pop())
            getList(data.prevData.pop())
            data.targetIndex = data.blockSize - 1
            break
        default:
            if (curBlock - 1 > 0) {
                // 배열에 맞는 인덱스를 셋 한다.
                const correctionNum = (curBlock - 1) * data.blockSize + 1
                const setOrder = value.Page - correctionNum
                console.log(setOrder)
                data.targetIndex = setOrder
            } else {
                data.targetIndex = value.Page - 1
            }
    }
}

export const pageSplit = (data, board, pageSize) => {
    const resultArr = []
    if (board) {
        const newitems = board.items
        const copyArr = []
        const cnt =
            Math.floor(newitems.length / pageSize) +
            (Math.floor(newitems.length % pageSize) > 0 ? 1 : 0)
        for (const value of newitems) {
            copyArr.push(value)
        }
        for (let i = 0; i < cnt; i++) {
            resultArr.push(copyArr.splice(0, pageSize))
        }
        data.page = resultArr
    }
}

// ↵ <- 해당 특수문자 대응
export const SlashNConverter = (htmldata) => {
    const convert = ''
    if (htmldata !== undefined) {
        // console.log(this.conData.content)
        const convert = htmldata.replace(/\n/gi, '<br>')
        return convert
    }
    return convert
}

// 등락률 '+' => 빨간색, '-' => 파란색으로 표시하는 함수
export const FluctuationColor = (htmldata) => {
    const plusPattern = new RegExp('([+]+[0-9]+.[0-9]+[%|P|원])|(?<=\\()\\+(?=\\))', 'g')
    const minusPattern = new RegExp('([-]+[0-9]+.[0-9]+[%|P|원])|(?<=\\()\\-(?=\\))', 'g')

    if (htmldata !== undefined) {
        let result = htmldata.replaceAll(
            plusPattern,
            (value) => `<nobr style="color: red">${value}</nobr>`
        )
        result = result.replaceAll(
            minusPattern,
            (value) => `<nobr style="color: blue">${value}</nobr>`
        )

        return result
    }
    return 'html 데이터를 가져올 수 없습니다.'
}

/* =========================================================== */

/**
 * class name 추가, 삭제
 */
export const addClassName = (element, className) => {
    //  element, name, arr;
    // const element = document.getElementById('myDIV')
    // const name = 'mystyle'
    const classNames = element.className.split(' ')

    // eslint-disable-next-line
    if (classNames.indexOf(className) === -1) {
        element.className += ' ' + className
    }
}
export const removeClassName = (element, className) => {
    element.classList.remove(className)
}
export const toggleClassName = (element, className) => {
    if (element.classList) {
        element.classList.toggle(className)
    } else {
        // For IE9
        const classNames = element.className.split(' ')
        // eslint-disable-next-line
        const i = classNames.indexOf(className)

        if (i >= 0) classNames.splice(i, 1)
        else classNames.push(className)

        element.className = classNames.join(' ')
    }
}

/*
20210510 shb 작성
당일 yyyy.mm.dd
*/
export const getToday = () => {
    const newDate = new Date()
    const y = newDate.getFullYear() // 년
    const m = newDate.getMonth() + 1 // 월
    const d = newDate.getDate() // 날짜
    return `${y}${m}${d}`
}


export function getNow() {
    const newDate = new Date()
    const yyyy = newDate.getFullYear().toString();
    const MM = pad(newDate.getMonth() + 1,2);
    const dd = pad(newDate.getDate(), 2);
    const hh = pad(newDate.getHours(), 2);
    const mm = pad(newDate.getMinutes(), 2)
    const ss = pad(newDate.getSeconds(), 2)
    return `${yyyy}${MM}${dd}${hh}${mm}${ss}`
}

function pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

/*
=================
*/

/*
20210511 shb 작성
paging 을 위한 배열 나누기
*/
export const arrSplit = (arr, size) => {
    let i, j
    const resultArr = []
    for (i = 0, j = arr.length; i < j; i += size) {
        resultArr.push(arr.slice(i, i + size))
    }
    return resultArr
}

/*
=================
*/

/*
20210525 shb 추가
object 빈값 체크
20210908 shb 수정
null 처리
 */
export const isEmptyObject = (param) => {
    if(!(param === undefined || param === null)){
        if (Array.isArray(param)) {
            if (param.length === 0) {
                return true
            }
            return false
        } else {
                if (Object.keys(param).length === 0 && param.constructor === Object) {
                    return true
                }

            return false
        }
    }
    return true
}
/*
=================
*/

export const classUpDownConverter = (value) => {

    // 숫자에 콤마 있는 경우 처리
    if( typeof value === 'string' && value.includes(",")) {
        value = value.replaceAll(",", "")
    }

    if(value > 0) {
        // plus
        return 'plus'
    } else if (value < 0) {
        // minus
        return 'minus'
    } else {
        // not changed
        return ''
    }
}

//
export const rateHundredCalc = (value) => {
    if(isEmptyObject(value)) return '해당 데이터가 없습니다.'
    else return (value / 100) + "%"
}

export const rateHundredCalc2 = (value) => {
    if(isEmptyObject(value)) return '해당 데이터가 없습니다.'
    else return (value / 100)
}
