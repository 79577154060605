import api from '~/api/' // server

const initialState = {
    newsList: {},
    curContent: null,
    colList: ['title', 'sendDate'],
    isEarning: false, // 주요기업실적 구분변수
}

const state = () => ({
    ...initialState,
})

const mutations = {
    SET_LIST(state, { data, newsType }) {
        // state.newsType[newsType] = data // not reactive
        // object value update
        state.newsList = { ...state.newsList, [newsType]: data }
        state.curContent = null
    },
    SET_CONTENT(state, { data }) {
        // state.newsType[newsType] = data // not reactive
        // object value update
        state.curContent = data
    },
    SET_COL(state, data) {
        state.colList = data
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
    SET_ISEARNING(state, data) {
        state.isEarning = data
    },
}

const actions = {
    async GET_LIST({ commit }, params) {
        // console.warn('GET_LIST params', params)
        const { newsType } = params

        /* const items = await this.$axios.$get(
      'https://jsonplaceholder.typicode.com/todos'
    ) */

        // const { data } = await api.getBoardList(params)
        // // console.warn('GET_LIST data', data)
        // commit('SET_LIST', { data, newsType })
        // return data
        // 220816 meej main error page
        const { data } = await api.getBoardList(params)
        .then((res)=>{
            if (res.success) {
                commit('SET_LIST', res.data, {newsType})
            }else{
                console.log("main_page board -> ",res.success)
                alert(res.message)
                commit('SET_LIST', null, {newsType})
            }
        })
        .catch((err)=>{
            console.log("main_page board -> ", err)
            alert(err)
            commit('SET_LIST', null, {newsType})
        })
        return data
    },

    async GET_CONTENT({ commit }, params) {
        const { data } = await api.getBoardContent(params)
        // console.warn('SET_CONTENT data', data)
        // commit('SET_CONTENT', { data })

        return data // curContent에 저장하지 않고 component에서 바로 저장해서 사용하도록 하기 위해
    },

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {
    getTitle(state) {
        return (newsType, id) =>
            state.newsList[newsType].items.find((List) => List.id === id)
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
