//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '@glidejs/glide/src/assets/sass/glide.core.scss'
import Glide from '@glidejs/glide'
import api from '~/api/' // server

export default {
    components: {
        // Flicking,
    },
    data() {
        return {
            banners: [
                // http://192.168.0.107/MobileStockPaper/20210913080806/AM?id=163148808648871&newsType=MARKET_MOBILENEWS_AM
                {
                    img: 'banner/banner2.png',
                    linkTo: 'https://www.youtube.com/watch?v=K70HKkfnsB8&list=PLk6nJJB8ltuv8XZNL5klcOAj5IWQl7VVx',
                },
                {
                    img: 'banner/banner3.png',
                    linkTo: 'https://www.youtube.com/watch?v=X8Ikny8gcDs&list=PLk6nJJB8ltuvOix5Da5O106eZvwn3keEj',
                },
                {
                    img: 'banner/banner4.png',
                    linkTo: 'https://www.youtube.com/watch?v=X8Ikny8gcDs&list=PLk6nJJB8ltuvOix5Da5O106eZvwn3keEj',
                },
                {
                    img: 'banner/banner5.png',
                    linkTo: 'https://www.youtube.com/watch?v=mqjiLzUje6M&list=PLk6nJJB8ltutQBR9uFDYs3ibaxN8HTjp8',
                },
            ],
            newsList: [],
        }
    },
    methods: {
        async getPaperNews() {
            const params = { menuType: 'MENU_MOBILENEWS', count: 5 }
            const newsList = (await api.getBoardList(params)).data
        },
    },
}
