import api from '~/api/' // server

const initialState = {
    memberInfo: null,
}

const state = () => ({
    showPayment: false,
    // memberId: '',
    memberInfo: initialState.memberInfo,
    memberinfoTab: 0,
    memberPhoneNo: null,
})

const mutations = {
    SET_SHOW_PAYMENT(state, show) {
        state.showPayment = show
    },

    /* SET_MEMBER_ID(state, id) {
    state.memberId = id
  }, */
    SET_MEMBER_INFO(state, data) {
        state.memberInfo = { ...data }
    },
    RESET_MEMBER_INFO(state) {
        state.memberInfo = initialState.memberInfo
    },
    SET_DEPOSIT_PAGE(state) {
        state.memberinfoTab = 2
    },
    SET_CLEAR_MEMBERINFO(state) {
        state.memberInfo = initialState.memberInfo
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
    SET_MEMBER_PHONE_NUM(state, memberPhoneNo){
        state.memberPhoneNo = memberPhoneNo;
    }
}

const actions = {
    async GET_MEMBER_INFO({ commit }, params) {
        // const { data } = await api.getMemberInfo(params)
        // 220816 meej main error page
        await api.getMemberInfo(params)
        .then((res)=>{
            if (res && res.success) {
                commit('SET_MEMBER_INFO', res.data)
            }else{
                console.log("main_page member -> ", res.success)
                alert(res.message)
                commit('SET_MEMBER_INFO', null)
            }
        })
        .catch((err)=>{
            console.log("main_page member -> ", err)
            alert(err)
            commit('SET_MEMBER_INFO', null)
        })

        // 22.04.15 suye 준회원 권한 제거
        // 22.04.11 suye 초기 가입 후 2일 무료회원 날짜 구분
        // const today = new Date();
        // const year = today.getFullYear();
        // const month = ('0' + (today.getMonth() + 1)).slice(-2);
        // const day = ('0' + today.getDate()).slice(-2);
        // const todayString = year + month + day;  
        // const todayChk = Number(todayString)+3
        // if(String(todayChk) === data.endDate){
        //     data.type = 'ASSOCIATE'
        // }
    },

    async LOGOUT({ commit, dispatch }, params) {
        const res = await api.logout({}) // {}: 빈 object를 보냄

        if (res && res.success) {
            // store 회원 정보 삭제
            commit('RESET_MEMBER_INFO')
            dispatch('search/RESET_ALL_STORE_ITEM', null, { root: true })
            dispatch('board/RESET_ALL_STORE_ITEM', null, { root: true })
            dispatch('research/RESET_ALL_STORE_ITEM', null, { root: true })
            dispatch('theme/RESET_ALL_STORE_ITEM', null, { root: true })

            return true
        } else {
            return false
        }
    },

    /*
     ** 회원정보 수정
     */

    async UPDATE_PASSWORD({ commit }, params) {
        const result = await api.updatePassword(params)
        return result
    },

    async UPDATE_NICKNAME({ commit }, params) {
        // console.warn('GET_LIST params', params)

        // 서버에서 성공을 보내줬을 때, success true값 리턴
        try {
            const result = await api.updateNickname(params)
            return result
        } catch (e) {
            console.error('error=', e)
        }
    },

    async DELETE_ACCOUNT({ commit }, params) {
        const { data } = await api.deleteAccount(params)
    },

    CLEAR_MEMBERINFO({ commit }, params) {},

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters,
}