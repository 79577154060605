//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import api from '~/api/' // server

export default {
    name: 'FindPwd',
    props: {
        reset: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: null,
            phoneNo: null,
            certifiNo: null,
            certifiInput: false,
            isIdDupCheck: Boolean,
            certifiError: null,
            seconds: 0,
            btnStyleCon: 'disabled', // 아이디 확인 버튼 스타일 
            btnStyleCon2: 'disabled', // 인증번호발송 버튼 스타일 
            btnStyleBlock: 'cursor: not-allowed',
            btnStyleBlock2: 'cursor: not-allowed',
        }
    },

    computed: {
        ...mapState('modal', ['findIdShow', 'visibleChangeTab']),
    },
    watch: {
        seconds() {
            this.minutes(this.seconds)
        },
        id(val, oldVal) {
            console.log('val, oldVal', val, oldVal)
            // id 입력 변경 시 idDupCheck 초기화
            this.idDupCheck = null
            if(val){
                if(this.id.length >= 6){
                this.btnStyleCon = ''
                this.btnStyleBlock = ''
                } else {
                    this.btnStyleCon = 'disabled'
                    this.btnStyleBlock = 'cursor: not-allowed'
                }
            }
            
        },
        findIdShow() {
            if (!this.findIdShow) {
                this.resetData()
            }
        },
        phoneNo(){
            if(this.phoneNo){
                if(this.phoneNo.length === 10 || this.phoneNo.length === 11){
                    this.btnStyleCon2 = ''
                    this.btnStyleBlock2 = ''
                } else {
                    this.btnStyleCon2 = 'disabled'
                    this.btnStyleBlock2 = 'cursor: not-allowed'
                }
            }
        },

    },
    created(){
        this.resetData()
    },
    methods: {
        ...mapMutations('modal', ['SET_VISIBLECHANGETAB',]),
        ...mapActions('modal', ['HIDE_MODAL']),

        minutes(time) {
            const minutes = Math.floor(time / 60)
            let seconds = time - minutes * 60

            if (seconds < 10) {
                seconds = '0' + seconds
            }

            const converted = minutes + ':' + seconds
            return converted
        },
        tickingTimer() {
            if (this.seconds > 0) {
                this.seconds--

                this.timer = setTimeout(() => {
                    this.tickingTimer()
                }, 1000)
            } else {
                clearTimeout(this.timer)
                this.seconds = -1
            }
        },

        async sendNo() {
            const res = await api.requestCertiCode({
                id: this.id,
                phone: this.phoneNo,
            })
            if (res && res.success) {
                // 요청버튼 눌렀을 때, 시간 할당 후, 타이머 시작
                this.seconds = 180
                this.tickingTimer()
                this.certifiInput = true
                this.btnStyleCon2 = 'disabled'
                this.btnStyleBlock2 = 'cursor: not-allowed'
            } else {
                alert('휴대폰 인증시 오류가 발생하였습니다.')
                clearTimeout(this.timer)
                this.certifiInput = false
                this.btnStyleCon2 = ''
                this.btnStyleBlock2 = ''
            }
        },
        async sendCertifi() {
            this.certifiError = null
            const res = await api.findIdPwd({
                code: this.certifiNo,
                phone: this.phoneNo,
                id: this.id,
            })

            if (res && res.success) {
                this.seconds = 0
                this.certifiError = null
                clearTimeout(this.timer)
                this.$emit('pwdFindSuccess') 
            } else if (res && res.status === '404') {
                this.certifiError = '인증 번호를 확인해주세요.'
            } else {
                this.certifiError = res
            }
        },

        async checkIdDup() {
            const params = {
                id: this.id,
            }
            const res = await api.checkIdDup(params)
            console.log('checkIdDup res', res)
            if (res) {
                if (res.success) {
                    this.isIdDupCheck = res.success
                } else {
                    this.isIdDupCheck = res.success
                }
            }
        },

        onClickIdDupCheck() {
            this.checkIdDup()
        },

        resetData() {
            this.id = null
            this.phoneNo = null
            this.certifiNo = null
            this.certifiInput = false
            this.isIdDupCheck = Boolean
            // this.isIDkorean = false
        },

        onClickClose() {
            this.resetData()
            this.HIDE_MODAL()
            this.seconds = 0
        },
        changeIdValue(e) {
            // this.isIDkorean = false
            const value = e.target.value

            // const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/

            // 한글이 아닌 경우에만, id에 value 할당
            // 22.04.28 suye 입력값 한글일 경우 알림
            // if (reg.exec(value) === null) {
            //     // this.id = this.id.slice(0, -1)
            //     this.id = value
            // } else {
            //     this.isIDkorean = true
            //     this.id = value
            // }
            this.id = value
        },
    },
}
