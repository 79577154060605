const initialState = {
    mobileMenuShow: false,
    isOpenPopup: false,
    isVisible: true, // header,footer hide
    isPopupOpen: false, // 팝업 
}

const state = () => ({
    ...initialState,
})

const mutations = {
    SET_MOBILE_MENU_SHOW(state, show) {
        state.mobileMenuShow = show
    },
    SET_OPEN_POPUP(state) {
        state.isOpenPopup = true
    },
    SET_CLOSE_POPUP(state) {
        state.isOpenPopup = false
    },
    SET_ISVISIBLE(state, show){
        state.isVisible = show
    },
    SET_ISPOPUP_OPEN(state, show){
        state.isPopupOpen = show
    },
}

const actions = {
    OPEN_POPUP({ commit }) {
        console.log("OPEN_POPUP")
        commit('SET_OPEN_POPUP')
    },
    CLOSE_POPUP({ commit }) {
        commit('SET_CLOSE_POPUP')
    }
}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters,
}
