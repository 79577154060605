// import createMultiTabState from 'vuex-multi-tab-state'
import api from '~/api/' // server
// import api from '~/api/mock' // local

const initialState = {
    newsList: {},
    curContent: null,
}

const state = () => ({
    ...initialState,
})

const mutations = {
    SET_LIST(state, data) {
        // state.newsType[newsType] = data // not reactive
        // object value update
        state.newsList = data
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    async GET_LIST({ commit }, params) {
        console.log(params)
        // console.warn('GET_LIST params', params)
        const { newsType1, id } = params

        const { data } = await api.getNewsPaper({ newsType: newsType1, id })
        // console.warn('GET_LIST data', data)
        commit('SET_LIST', data)
        // return data
    },

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}
// const plugins = [createMultiTabState({ newsList: ['newsList'] })]

export default {
    state,
    mutations,
    actions,
}
