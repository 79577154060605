//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import Banner from '~/components/Common/Layout/Footer/Banner'
import { APP_VERSION } from '~/constants'


export default {
    name: 'DefaultFooter',
    components: {
        Banner,
    },
    data() {
        return {
            APP_VERSION,
        }
    },
     computed: {
        ...mapState('ui', ['isVisible',]),
    },
}
