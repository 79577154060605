/* 20210518 shb 최초 작성 */
const initialState = {
// 22.03.24 한수예
// blockSize: 몇 페이지째 넘기고 있는지, block: 몇 개의 페이지 넘버를 보여줄 건지

    // 한블럭 당 페이지 갯수
    pageCount: 0,
    // 페이지 당 row 갯수
    blockSize: 0,
    // 현재 블록 위치
    blockLocation: 0,
    // 현재 페이지
    curPage: 0,
    // nextKey
    nextKey: '',
    prevKey: '',
    prevKeyList: [],
    last : true
}

const state = () => ({
    ...initialState,
})

const mutations = {
    RESET_STORE(state) {
        console.log('RESET_STORE')
        state.pageCount = 0
        state.pageRowCount = 0
        state.blockSize = 0
        state.blockLocation = 0
        state.curPage = 0
        state.nextKey = ''
        state.prevKey = ''
        state.prevKeyList = []
        state.last = true
    },
    SET_PAGING(state, payload) {
        console.log('SET_PAGING')
        
        /* 
            220411 meej nextKey 설정 수정
            220823 meej nextKey 조건 추가
        */
        console.log('payload.nextKey', payload.nextKey)

        if(payload.nextKey === null || payload.nextKey === undefined){
            state.last = false
            state.nextKey = null
        // 22.12.14 SUYE nextKey null로 인한 페이징 오류 수정 
        } else if (payload.nextKey.length === 12){
            payload.nextKey = payload.nextKey + '00'
            state.nextKey = payload.nextKey
        }
        else{
            let sliceString = JSON.stringify(payload.nextKey)
            sliceString = sliceString.slice(sliceString.length-5, sliceString.length -1)
            if (sliceString === 'null'){
                sliceString = null
                state.last = false
                state.nextKey = sliceString
            }
            else{
                state.last = true
                state.nextKey = payload.nextKey
            }
        }
        state.pageCount = payload.count
        state.blockSize = payload.block
        // console.log('check Params: ', state.nextKey, state.pageCount, state.blockSize)
    },
    SET_PREV_KEY(state, payload) {
        state.prevKey = payload
    },
    SET_PAGE(state, page) {
        console.log('SET_PAGE')
        state.curPage = page
        // state.pageRowCount = row
    },
    SET_NEXT(state) {
        console.log('SET_NEXT')
        // next 시 첫 페이지 로..
        state.curPage = 0
        state.prevKeyList.push(state.prevKey)
        ++state.blockLocation
    },
    SET_PREV(state) {
        --state.blockLocation
        // // prev 시 첫 마지막 페이지로..
        /* 
            220321 meej
            이전누르면 무조건 마지막페이지로 : '4' 
        */
        // state.curPage = state.pageCount - 1
        state.curPage = 4
        state.prevKey = state.prevKeyList.pop()
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
    SET_RESET_CURRENT_PAGE(state) {
        state.curPage = 0

        console.log("SET_RESET_CURRENT_PAGE=", state)
    },
    SET_LAST(state) {
        state.last = false
    }
}
const actions = {
    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },

    RESET_CURRENT_PAGE({commit}) {
        setTimeout(() => {}, 1000)
        commit('SET_RESET_CURRENT_PAGE')
    }
}

const getters = {
    getViewCount(state) {
        return state.blockLocation * state.blockSize
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}
