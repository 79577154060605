// initial state
const state = () => ({
    tabGroup: {
        Research: [
            {
                name: 'DailyRecommend',
                title: '데일리신규추천',
                route: '/Research/StockFirmRecommend/DailyRecommend',
                on: false,
            },
            {
                name: 'RecommendByStockFirm',
                title: '증권사별 추천종목',
                route: '/Research/StockFirmRecommend/RecommendByStockFirm',
                on: false,
            },
            {
                name: 'ExcludeRecommend',
                title: '추천 제외종목',
                route: '/Research/StockFirmRecommend/ExcludeRecommend',
                on: false,
            },
            {
                name: 'PortfolioEarningRateRanking',
                title: '포트폴리오 수익률 순위',
                route:
                    '/Research/StockFirmRecommend/PortfolioEarningRateRanking',
                on: false,
            },
            {
                name: 'StockEarningRateRanking',
                title: '종목별 수익률 순위',
                route: '/Research/StockFirmRecommend/StockEarningRateRanking',
                on: false,
            },
        ],
    },
})

/**
 * mutation-types.js로 뺄지 고려 중
 */
const mutations = {
    TABSCONTENT_SET(state, payload) {
        const content = state.tabGroup
        for (const key in content) {
            if (key === payload.tabGroup) {
                const con = content[key]
                for (let i = 0; i < con.length; i++) {
                    con[i].on = false
                    if (con[i].name === payload.onTab) {
                        con[i].on = true
                    }
                }
            }
        }
    },
}

const actions = {}

const getters = {
    // getThemeListItems: (state) => {
    //   console.log(state.themelist.data)
    //   console.log(state.themelist.data && state.themelist.data.items)
    //   // return state.themelist.data.items;
    // },
    // (key) =>
    // getTabGroup: ({ state }, ggg) => {
    // for (const key in state.tabContent) {
    // console.log(state.tabContent[key])
    // console.log('dd', d)
    // if (key === d) {
    //   console.log('key', key)
    //   console.log('data[key]', state.tabContent[key])
    // }
    // const ddd = state[key]
    // for (const key2 in ddd) {
    //   console.log('key1', key2)
    //   console.log('data[key]1', ddd[key2])
    // }
    // }
    // console.log('ddddddd', ggg)
    // // const evadl = new Function('return df')()
    // find('dfsdf')
    // const a = state.tabContent.
    // return 'd'
    // },
}

export default {
    // namespaced: true
    state,
    mutations,
    actions,
    getters,
}
