//
//
//
//
//
//
//
//

// import Header from '~/components/Common/Layout/Header'
// import Footer from '~/components/Common/Layout/Footer'

export default {
  // components: { Header },
}
