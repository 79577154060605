import { setClient } from '~/services/apiClient'

import storage, { keys } from '~/services/storage'

/**
 * axios 공통 처리
 */
export default ({
    store,
    $axios,
    app,
    $method, // custom plugin
    redirect,
}) => {
    // console.log('plugins/axios.js store', store)
    // console.log('plugins/axios.js $method', $method)

    // app.router.beforeEach(async (to, from, next) => {})

    $axios.onRequest((config) => {
        // console.log('Making request to ' + config.url)
        // config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    })

    $axios.onError((error) => {
        console.log('$axios.onError error', error)
        // console.log('$axios.onError error.response', error.response)

        const code = parseInt(error.response && error.response.status)
        console.log('$axios.onError code', code)

        if (code !== 200) {
            const msg =
                error.response &&
                error.response.data &&
                error.response.data.message
            const errorCode =
                error.response &&
                error.response.data &&
                error.response.data.error

            if (errorCode === 'INVALID_SESSION') {
                // redirect('/400')
                console.log('INVALID_SESSION')

                // app 내부 로그인 상태 삭제
                // storage.remove(keys.loginInfo)
                // global method로 교체
                $method.logout()
            } else if (errorCode === 'PERMISSION_DENIED') {
                // TODO: from 정보 가져와서 리다이렉트 추가필요
                redirect('/')
                // store.dispatch('modal/SHOW_ALERT', {
                //     content: msg,
                // })
                alert(msg)
            }

            /* else {
            // alert(`Error: ${msg}`)
            // } */
        }
    })

    setClient($axios) // save instacne reference, to use in non-vue file
}
