//
//
//
//
//
//
//
//
//
//
//

import TickerVertical from '~/components/Common/UI/TickerVertical'
import TickerHorizental from '~/components/Common/UI/TickerHorizental'

export default {
  name: 'NewsTicker',
  components: {
    TickerHorizental,
  },
}
