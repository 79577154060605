// import api from '~/api/' // server
// import api from '~/api/mock' // local

const initialState = {
    alertShow: false,
    alertInfo: { content: '', desc: '', callback: null },

    loginShow: false,
    loginPath: '',

    findIdShow: false,
    findIdShow_SNS: false,

    regularMemberJoinShow: false,
    regularMemberJoinInfo: { callback: null },


    regularMemberWelcomeShow: false,
    regularMemberWelcomeInfo: { callback: null },

    depositShow: false,
    depositPayInfo: {},
    depositInfo: { show: false, callback: null },
    // 20210824 shb 기업 소개 추가
    introduction: { show : false, flag: null},

    // 22.03.18 한수예 결제창 모달 추가
    paymentShow : false,
    curChangeIdx: -1,
    showPaymentLocal: false,
    // paymentTypeIdx: 0,
    
    // 22.04.29 suye 비밀번호 변경 모달창 구분값
    visibleChangeTab: false,

    // depositInfo: { show: false, callback: null },

    // modalData: {
    //   title: '알림',
    //   content: '',
    // },
}

const state = () => ({
    ...initialState,
})

const mutations = {

    // 22.04.29 suye 비밀번호 변경 모달창 제어
    SET_VISIBLECHANGETAB(state, show){
        state.visibleChangeTab = show
    },
    // 22.03.18 한수예: 결제창 
    SET_PAYMENT_SHOW(state, show) {
        state.paymentShow = show
    },
    SET_CURCHANGEIDX(state, show) {
        console.log('curChangeIdx: ', show)
        state.curChangeIdx = show
    }, 
    SET_SHOWPAYMENTLOCAL(state, show) {
        state.showPaymentLocal = show
    },
    // SET_PAYMENTTYPEIDX(state, val) {
    //     state.paymentTypeIdx = val
    // },

    SET_ALERT_SHOW(state, { show, info }) {
        console.log('SET_ALERT_SHOW show', show, info)
        // state = { ...state, alertShow: show }
        // console.log('state', state)
        state.alertShow = show
        state.alertInfo = info
    },
    SET_LOGIN_SHOW(state, { show, path }) {
        console.log('SET_LOGIN_SHOW show', show, path)
        state.loginShow = show
        state.loginPath = path
    },
    SET_FIND_ID_SHOW(state, { show }) {
        console.log('SET_FIND_ID_SHOW show', show)
        state.findIdShow = show
    },
    // SNS가입 휴대폰번호
    SET_FIND_ID_SHOW_SNS(state, { show }) {
        console.log('SET_FIND_ID_SHOW_SNS show', show)
        state.findIdShow_SNS = show
    },
    // 정회원 가입 안내 modal
    SET_REGULAR_MEMBER_JOIN_SHOW(state, { show, info }) {
        console.log('SET_REGULAR_MEMBER_JOIN_SHOW show', show, info)
        state.regularMemberJoinShow = show
        state.regularMemberJoinInfo = info
    },
    SET_REGULAR_MEMBER_WELCOME_SHOW(state, { show, info }) {
        console.log('SET_REGULAR_MEMBER_WELCOME_SHOW show', show, info)
        state.regularMemberWelcomeShow = show
        state.regularMemberWelcomeInfo = info
    },
    SET_DEPOSIT_INFO(state, info) {
        console.log('SET_DEPOSIT_INFO info', info)
        state.depositInfo = { ...info }
    },
    SET_DEPOSIT_PERIOD(state, payInfo) {
        console.log('payInfo=', payInfo)
        state.depositPayInfo = { ...payInfo }
    },
    // 20210824 shb 기업 소개 추가
    SET_INTRODUCTION(state, data) {
        console.log('payInfo=', data)
        state.introduction = data
        console.log(state.introduction)
        console.log("---------------------")

    },
    CLEAR_DEPOSIT_INFO(state) {
        state.depositInfo = { ...initialState.depositInfo }
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    SHOW_ALERT({ commit }, info) {
        console.log('SHOW_ALERT info', info)
        commit('SET_ALERT_SHOW', { show: true, info })
    },
    // HIDE_ALERT({ commit }) {
    //   commit('SET_ALERT_SHOW', { show: false })
    // },
    SHOW_LOGIN({ commit }, path) {
        commit('SET_LOGIN_SHOW', { show: true, path })
    },
    // SHOW_PAYMENT({ commit }) {
    //     commit('SET_PAYMENT_SHOW', { show: true })
    // }, 
    SHOW_FIND_ID({ commit }) {
        commit('SET_FIND_ID_SHOW', { show: true })
    },
    // SNS로그인 모달 
    SHOW_FIND_ID_SNS({ commit }) {
        commit('SET_FIND_ID_SHOW_SNS', { show: true })
    },
    // 정회원 가입 안내 modal
    SHOW_REGULAR_MEMBER_JOIN({ commit }, info) {
        console.log('SHOW_REGULAR_MEMBER_JOIN info', info)
        commit('SET_REGULAR_MEMBER_JOIN_SHOW', { show: true, info })
    },
    // 정회원 가입 환영 modal
    SHOW_REGULAR_MEMBER_WELCOME({ commit }, info) {
        console.log('SHOW_REGULAR_MEMBER_WELCOME info', info)
        commit('SET_REGULAR_MEMBER_WELCOME_SHOW', { show: true, info })
    },
    // 무통장 입금
    SHOW_DEPOSIT({ commit }, info = {}) {
        console.log('SHOW_DEPOSIT info', info)
        commit('SET_DEPOSIT_INFO', { show: true, ...info })
    },
    // 20210824 shb 기업 소개 추가
    SHOW_INTRODUCTION({ commit },data) {
        console.log(data)
        commit('SET_INTRODUCTION', { show: true, flag:data })
    },

    PUT_DEPOSIT_PERIOD({ commit }, { data }) {
        commit('SET_DEPOSIT_PERIOD', data)
    },

    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },

    HIDE_MODAL({ commit }) {
        commit('SET_ALERT_SHOW', {
            show: false,
            alertInfo: { ...initialState.alertInfo },
        })
        commit('SET_FIND_ID_SHOW', { show: false })
        commit('SET_FIND_ID_SHOW_SNS', { show: false })
        commit('SET_INTRODUCTION', { show: false })
        commit('SET_REGULAR_MEMBER_JOIN_SHOW', { show: false })
        commit('SET_REGULAR_MEMBER_WELCOME_SHOW', { show: false })
        commit('SET_DEPOSIT_INFO', { ...initialState.depositInfo })
        commit('SET_LOGIN_SHOW', { show: false })
        // 220608 meej 결제창 숨김 따로 처리
        // commit('SET_PAYMENT_SHOW', { show: false })
    },
    /* 
        220608 meej 결제창 숨김 따로 처리
     */
    HIDE_PAY_MODAL({ commit }) {
        commit('SET_PAYMENT_SHOW', { show: false })
    },
}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters,
}
