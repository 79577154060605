// 비회원 접근 가능한 URL 정의
export default {
    'allow-nomember': [
        '/',
        '/Login',
        '/SignUp',
        '/Policy',
        '/Principle',
        '/PolicyYouth',
        '/Care',
        '/IR_groups',
        '/CustomerCenter/Notice',
        '/CustomerCenter/Ad',
        '/MobileStockPaper',
        '/MarketNewsFlash',
        '/Company/TechnicalAnalysis',
        '/Company/QuoteUnusual',
        '/Company/DailyShareChange',
        '/Company/DailyTreasuryStock',
        '/Company/LoanTransaction',
        '/Company/CreditRatioRanking',
        '/Company/ForeignBuySellRanking',
        '/Company/ForeignInstBuySellRanking',
        '/Company/ForeignInstContinueRanking',
        '/Company/TodayPopularSearch',
        '/Company/RiseDropRateRanking',
        '/Company/VI',
        '/Company/100MBuySellRanking',
        '/Theme/ThemeBriefing',
        '/Theme/ThemeFluctuations',
        '/Research/InfoStockInsight',
        '/MarketNews/MajorCompaniesFluctuationRate',
        '/MarketNews/ExchangeRate_ProductsTrend',
        '/MarketNews/MajorCountriesIndex',
        '/IR_B2B/IR',
        '/IR_B2B/FinancialNews',
        '/IR_B2B/FlashService',
        '/IR_B2B/MarketService',
        '/IR_B2B/ThemeService',
        '/IR_B2B/HistoryService',
        '/IR_B2B/DataService',
        '/IR_B2B/ScheduleService',
        '/Renewal',
        '/DailyMarket',
        '/TodayWorld',
        '/Verification',
        '/Ticker'
    ],
}
