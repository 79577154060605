// import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

/* const test = {
  name: 'Test',
  ping() {
    console.log('Ping')
  },
} */

import storage, { keys } from '~/services/storage'

export default ({ store, app, redirect }, inject) => {
    const method = {
        /**
         * 사용하는 곳 예시
         * this.$method.logout(this.logoutCallback)
         */
        logout(callback) {
            // const { LOGOUT } = mapActions('member', ['LOGOUT'])
            // console.log('LOGOUT', LOGOUT)

            // const result = LOGOUT()

            // console.log('plugins/method.js method store', store)
            // 210303 이민수. 헬퍼 사용 시, $store 에러로 인해, 임시로 주석 해제
            const result = store.dispatch('member/LOGOUT')

            if (result) {
                // storage 로그인 상태 삭제
                storage.remove(keys.loginInfo)

                // this.SHOW_ALERT({ content: '로그아웃 성공' })
                // store.dispatch('modal/SHOW_ALERT', { content: '로그아웃 성공' })

                callback && callback()

                redirect('/')
            } else {
                this.SHOW_ALERT({ content: '로그아웃 실패' })
            }
        },
    }

    inject('method', method)
}
